
/*
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*/

@font-face {
    font-family: "remixicon";
    src: url('remixicon.eot?t=1638393193968'); /* IE9*/
    src: url('remixicon.eot?t=1638393193968#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url("remixicon.woff2?t=1638393193968") format("woff2"),
    url("remixicon.woff?t=1638393193968") format("woff"),
    url('remixicon.ttf?t=1638393193968') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
    url('remixicon.svg?t=1638393193968#remixicon') format('svg'); /* iOS 4.1- */
    font-display: swap;
}

[class^="ri-"], [class*="ri-"] {
    font-family: 'remixicon' !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ri-lg { font-size: 1.3333em; line-height: 0.75em; vertical-align: -.0667em; }
.ri-xl { font-size: 1.5em; line-height: 0.6666em; vertical-align: -.075em; }
.ri-xxs { font-size: .5em; }
.ri-xs { font-size: .75em; }
.ri-sm { font-size: .875em }
.ri-1x { font-size: 1em; }
.ri-2x { font-size: 2em; }
.ri-3x { font-size: 3em; }
.ri-4x { font-size: 4em; }
.ri-5x { font-size: 5em; }
.ri-6x { font-size: 6em; }
.ri-7x { font-size: 7em; }
.ri-8x { font-size: 8em; }
.ri-9x { font-size: 9em; }
.ri-10x { font-size: 10em; }
.ri-fw { text-align: center; width: 1.25em; }

.ri-vip-diamond-fill:before { content: "\f28f"; }
.ri-bar-chart-2-fill:before { content: "\ea95"; }
.ri-shopping-cart-fill:before { content: "\f11f"; }
.ri-ink-bottle-fill:before { content: "\ee5c"; }
.ri-book-2-fill:before { content: "\ead2"; }
.ri-calendar-fill:before { content: "\eb26"; }
.ri-global-fill:before { content: "\edce"; }
.ri-service-fill:before { content: "\f0e1"; }
.ri-menu-line:before { content: "\ef3e"; }
