@import 'css-pro-layout/dist/scss/css-pro-layout.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '../../public//assets//remixicon/remixicon.css';
@import './variables';
@import './layout';
@import './sidebar';
@import './menu';

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  color: #212529;
}

a {
  text-decoration: none;
}

@media (max-width: $breakpoint-lg) {
  #btn-collapse {
    display: none;
  }
}
