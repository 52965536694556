.sidebar {
  color: $text-color;
  overflow-x: hidden !important;
  position: relative;
  background-color: $bg-color;

  .image-wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    z-index: 1;
    display: none;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &.has-bg-image .image-wrapper {
    display: block;
  }

  .sidebar-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    .sidebar-header {
      height: $sidebar-header-height;
      min-height: $sidebar-header-height;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid $border-color;
      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .sidebar-content {
      flex-grow: 1;
      padding: 10px 0;
    }
    .sidebar-footer {
      height: $sidebar-footer-height;
      min-height: $sidebar-footer-height;
      display: flex;
      align-items: center;
      border-top: 1px solid $border-color;
      padding: 0 20px;
      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
